<template>
  <KTCard :page="page">
    <template #toolbar>
      <KTViewButton :to="addRoute" v-if="finalizado" />
      <KTEditButton :to="addRoute" v-else />
    </template>
    <template #body>
      <KTTable
        :fields="capital_giro_columns"
        :items="capital_giro.data"
        :primaryKey="capital_giro.primaryKey"
        :sortBy="capital_giro.sortBy"
        :paginated="false"
        :searchable="false"
      />
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipCapitalGiroList",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-cash-register",
        title: "Capital de Giro",
        description: `Capital de giro significa capital de trabalho, ou seja, o capital
          necessário para financiar a continuidade das operações da empresa, como recursos
          para financiamento aos clientes (nas vendas a prazo), recursos para manter estoques e
          recursos para pagamento aos fornecedores (compras de matéria-prima, insumos
          diversos, embalagens), pagamento de impostos, salários e demais custos e despesas
          operacionais. Insira os parâmetros adequados para dimensionar a Necessidade de
          Capital de Giro (NCG), através do botão Editar.`
      },
      capital_giro: {
        data: [],
        primaryKey: null,
        sortBy: ""
      },
      anos: 0,
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/capital_giro`;
    },
    manual() {
      return {
        session: "capital_giro",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    addRoute() {
      return {
        name: "pip_capital_giro_new",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    capital_giro_columns() {
      let columns = [
        {
          key: "descricao",
          label: "Descrição",
          sortable: false
        },
        {
          key: "nome_unidade_medida",
          label: "Unidade de Medida",
          sortable: false,
          tdClass: "text-center"
        }
      ];

      for (let ano = 1; ano <= this.anos; ano++) {
        columns.push({
          key: "ano_" + ano,
          label: "Ano " + ano,
          sortable: false,
          tdClass: "text-right",
          formatter: "number"
        });
      }

      return columns;
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.capital_giro.data = res[R_GETLIST].capital_giro;
        this.anos = parseInt(res[R_GETLIST].anos);
        this.tipo_plano_investimento = res[R_GETLIST].tipo_plano_investimento;
        this.finalizado = res[R_GETLIST].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
